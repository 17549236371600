import { useState, useEffect } from "react";
import "./step4.1.css";
import { useAddress } from "@thirdweb-dev/react";
import Confetti from "react-confetti";
import * as amplitude from "@amplitude/analytics-browser";
import { EtherscanTransactionURL, WarpcastURL } from "../../../../constants/common-constants";

function LaunchMintSuccess({
  artistId,
  membershipId,
  stepChange,
  transactionDetails,
  postLaunch,
  nftTitle,
  nftMedia,
  programAttributes,
}) {
  const address = useAddress();
  const [isShortProgram, setIsShortProgram] = useState(false);

  const getPrimaryText = () => {
    if (isShortProgram && !postLaunch) {
      return `Share ${nftTitle} & Unlock`;
    } else if (isShortProgram && postLaunch) {
      return "Share the Art";
    } else {
      return "CONGRATULATIONS!";
    }
  };

  const getSecondaryText = () => {
    if (isShortProgram && !postLaunch) {
      return `Complimentary Artworks Unlocked for ${nftTitle} holders upon milestone achievement.`;
    } else if (isShortProgram && postLaunch) {
      return "";
    } else if (!isShortProgram && postLaunch) {
      return `You have minted ${nftTitle}.`;
    } else {
      return `You now own ${nftTitle}.`;
    }
  };

  const getFarcasterShareUrl = () => {
    let url = WarpcastURL;
    if (
      programAttributes &&
      "farcaster" in programAttributes &&
      ((postLaunch &&
        "post-launch-share-text" in programAttributes["farcaster"] &&
        "post-launch-share-url" in programAttributes["farcaster"]) ||
        (!postLaunch &&
          "launch-share-text" in programAttributes["farcaster"] &&
          "launch-share-url" in programAttributes["farcaster"]))
    ) {
      const shareText = postLaunch
        ? programAttributes["farcaster"]["post-launch-share-text"]
        : programAttributes["farcaster"]["launch-share-text"];
      const shareUrl = postLaunch
        ? programAttributes["farcaster"]["post-launch-share-url"]
        : programAttributes["farcaster"]["launch-share-url"];

      url = `${WarpcastURL}~/compose?text=${shareText}&embeds[]=${shareUrl}`;
    }
    return url;
  };

  useEffect(() => {
    if (programAttributes && "show-progress-bar" in programAttributes && programAttributes["show-progress-bar"]) {
      setIsShortProgram(true);
    }
    // console.log("launch/step4.1.js transactionDetails>>>", transactionDetails);
    // console.log("launch.step4.1.js>>>", nftMedia);
    // Trigger Amplitude Event
    try {
      amplitude.track("Post-Mint Page Viewed", {
        wallet_connected: address ? "True" : "False",
        minting: postLaunch ? "Perk1" : "Pass",
        artist_id: artistId,
        membership_id: membershipId,
        contract_address: transactionDetails.contractAddress,
        token_id: transactionDetails.tokenId,
        quantity: transactionDetails.mintCount,
      });
    } catch (e) {}
  }, []);

  return (
    <>
      <div style={{ zIndex: -1, position: "absolute", width: "100vw", height: "100vh" }}>
        <Confetti
          numberOfPieces={500}
          recycle={false}
          colors={[
            "#E4F1E8",
            "#C8E6E4",
            "#66C6CC",
            "#00A0AA",
            "#5999B2",
            "#EEA8B8",
            "#E1CAE1",
            "#937493",
            "#DE69A1",
            "#EA6383",
            "#FEA86D",
            "#FCD465",
            "#FFEC3F",
            "#FFF597",
            "#FFDE9D",
            "#F5AFD1",
            "#FFEDF6",
          ]}
        />
      </div>

      <div className="launch-mint-success-details-container">
        {nftMedia ? (
          <div className="launch-mint-success-details-nft-media-container">
            {!nftMedia ? (
              ""
            ) : nftMedia[0]["mediaType"] === "image" ? (
              <img style={{ height: "90%" }} src={nftMedia[0]["media"]} />
            ) : (
              <video height="90%" width="80%" controls autoPlay={true} muted loop={true}>
                <source src={nftMedia[0]["media"]} type="video/mp4" />
              </video>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="launch-mint-success-text-button-container">
          <div className="launch-mint-success-message-container">
            <div className="launch-mint-success-message-heading-text">{getPrimaryText()}</div>
            <div className="launch-mint-success-message-secondary-text">{getSecondaryText()}</div>
            <div
              onClick={() => {
                let url = `${EtherscanTransactionURL}/${transactionDetails.transactionHash}`;
                window.open(url, "_blank");
              }}
              className="launch-mint-success-transaction-url-container"
            >
              View on {process.env.REACT_APP_CHAIN == "Ethereum" ? "etherscan" : "basescan"}
            </div>
          </div>
          <div className="launch-mint-success-navigation-container">
            <div className="launch-mint-success-button-container">
              <div
                className="launch-mint-success-button"
                onClick={() => {
                  let url = getFarcasterShareUrl();
                  window.open(url, "_blank");
                }}
              >
                <img
                  className="launch-mint-success-warpcast-logo"
                  src={require("../../../../assets/warpcast-logo.webp")}
                />
                <div className="launch-mint-success-button-text">Share on Farcaster</div>
              </div>
            </div>
            <div className="launch-mint-success-home-button-container">
              <div
                className="launch-mint-success-home-button-text"
                onClick={() => {
                  stepChange(1);
                }}
              >
                Continue to Home
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaunchMintSuccess;
